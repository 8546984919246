
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    // import sendEmail from '../lib/services/emailService';
import HomePage from '../components/HomePage';
import { useState, useEffect } from 'react';
import Head from 'next/head';
function Home({}) {
    const [isLoading, setIsLoading] = useState(true);
    const [fetchedData, setFetchedData] = useState(null);
    // Fetch data on the client side
    const fetchData = async () => {
        try {
            const response = await fetch('/api/fetchData'); // Assuming you have an API route for fetching data
            if (!response.ok) {
                throw Error(response.statusText);
            }
            const data = await response.json();
            // Set the data in state or update your components as needed
            setFetchedData(data);
            setIsLoading(false);
        }
        catch (e) {
            // Handle error
            setFetchedData({
                error: 'Something went wrong. Im probably already working on it!'
            });
            setIsLoading(false);
        }
    };
    useEffect(() => {
        const fetchDataAndSetInterval = () => {
            fetchData(); // Initial data fetch
            const intervalId = setInterval(() => {
                fetchData();
            }, 1000 * 60);
            // Cleanup the interval when the component unmounts
            return () => {
                clearInterval(intervalId);
            };
        };
        fetchDataAndSetInterval(); // Call the function to set up the interval
        // Specify an empty dependency array to ensure this effect runs only once
    }, []);
    return (<>
            <Head>
                <title>CPH SECURITY WAIT TIME</title>
                <meta property="og:title" content="CPH SECURITY WAIT TIME - CPH SIKKERHEDSKONTROL VENTETID"></meta>
                <meta name="robots" content="all"/>
                <meta name="description" content="Get the current security wait time at CPH airport and also see the next delayed flights!"></meta>
                <meta property="og:description" content="Get the current security wait time at CPH airport and also see the next delayed flights!"></meta>
                <meta name="keywords" content="cph, airport, security wait time, kastrup, waiting time, security waiting time, fly, airplane, ventetid, sikkerhedskontrol, sas, lufthavn, delays, forsinkelser"></meta>
                <meta charSet="utf-8"/>
                <meta name="author" content="Morten Laursen"/>
                <meta name="copyright" content="Morten Laursen"/>
                <link rel="icon" href="/favicon.ico"/>
            </Head>
            <HomePage {...{
        waitTime: fetchedData?.waitTime,
        lastUpdated: fetchedData?.lastUpdated,
        metaTags: fetchedData?.metaTags,
        error: fetchedData?.error,
        delayedFlights: fetchedData?.delayedFlights,
        summary: fetchedData?.summary,
        lastUpdatedUpdated: fetchedData?.lastUpdatedUpdated,
        timezone: fetchedData?.timezone
    }}/>
        </>);
}
export default Home;

    async function __Next_Translate__getStaticProps__190abbc20f6__(ctx) {
      
      return {
        
        
        props: {
          
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__190abbc20f6__ as getStaticProps }
  